import(/* webpackMode: "eager" */ "/vercel/path0/apps/app/public/logo_dark_dot.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/app/public/ralph_buildings_pattern.svg");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitch"] */ "/vercel/path0/apps/app/src/appcomponents/language-switch.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast","promise"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
