import { clsx } from 'clsx'
import type { ClassValue } from 'clsx'
import { toast } from 'sonner'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

type ArgumentTypes = Parameters<typeof toast.promise<any>>

export const handleActionPromiseWithToast = (
  promise: Promise<any>,
  data: ArgumentTypes[1]
) => {
  const throwingPromise = async () => {
    const resp = await promise
    if (resp && resp.errorCode) {
      throw new Error(resp.errorCode)
    }
    return resp
  }
  return toast.promise(throwingPromise(), data)
}
